import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`See my `}<a parentName="p" {...{
        "href": "https://inspirehep.net/authors/1615040"
      }}>{`inSPIRE profile`}</a>{` and my `}<a parentName="p" {...{
        "href": "https://scholar.google.com/citations?user=BwbE-_UAAAAJ&hl=en"
      }}>{`Google Scholar profile`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      